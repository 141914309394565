.reviews-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.review-item {
    width: 50%;
    padding-bottom: 20px;
}
