.hidden {
    visibility: hidden;
}

.menu  {
    list-style: none;
    float: right;
    margin-right: 50px;

}


.menu li {
    display: inline;
    padding: 5px;
    margin: 10px;
}


.profile-dropdown {
    box-sizing: border-box;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding-inline-start: 0px;
    background-color: white;
    position: absolute;
}

.profile-dropdown > li {
    display: block;
    box-sizing: border-box;
}

.profile-item {
    margin: 20px;
}

.profile-dropdown > * {
    font-size: 14px;
}

.fa-bars {
    margin: 5px;

}

.fa-user-circle {
   margin-left: 5px;
}

.profile-item > button {
   background-color: transparent;
   border: none;
   font-size: 12px;
   font-family: sans-serif;

}

a {
    text-decoration: none;
    color: inherit
}

.user-button {
    border-radius: 15px;
    border: solid 1px rgba(167, 165, 165, .5);
    margin: 5px auto;
    background-color: transparent;
    height: 30px;
}

.user-button:hover {
    box-shadow: -5px 5px 5px gray;
    cursor: pointer;
}

.navBar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    width: 100%;
    height: 80px;
    box-sizing: border-box;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;
}

#logout {
    border: none;
    background-color: transparent;
    font-family: sans-serif;
    font-size: 14px;
    padding: 0px;
}

.logo {
    height: 60px;
    margin-top: 10px;
    margin-left: 30px;
}

.button-item :hover {
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.logo-name {
    display: flex
}

.site-name {
    padding-top: 10px;
    margin-left: 10px;
    color: red;
    font-family: Arial;
    font-size: 22px;
}
