.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

}

.spot-form {
    display: flex;
    flex-direction: column;
    width: 40%;
}



.spot-form > label {
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

}


.spot-form > label > input {
    height: 40px;
    width: 100%;
    border-radius: 10px;


}

.spot-form > label > textarea {
    margin-top: 5px;
    height: 50px;
    width: 100%;
    border-radius: 10px;
}

.spot-form > button {
    box-sizing: border-box;
    border-radius: 10px;
    border: none;
    background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
    color: white;
    margin-top: 50px;
    height: 50px;
}
