.card-container {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: block;
}



.img-container {
    width:100%;
    height:80%;
    object-fit: contain;
}

.img-container > img:hover {
    box-shadow: 2px 2px rgba(128, 128, 128, 0.7);
}

.img-container > img {
   object-fit: cover;
   width: 100%;
   height: 100%;
   border-radius: 10px;

}

.card-info {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    height: 50%;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
}

.info-container {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    height: 20%;

}

.price-container {
    height: 30%;
    display: flex;
}


.night {
    padding-left: 5px;
    font-weight:normal;
    font-size: 14px;
}
