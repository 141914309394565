.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.review-form {
  display: flex;
  flex-direction: column;
  width: 30%
}
.review-form > * {
  margin: 10px;
  height: 40px;
}

.review-form > label > input {
  float: right;
  width: 50%
}

.review-button {
  box-sizing: border-box;
  border-radius: 10px;
  border: none;
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
  color: white;
}
