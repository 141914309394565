.gallery {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-evenly;
    padding-left: 0px ;
    margin-right: 2%;
    margin-left: 2%;

}

.card {
    width: 15%;
    height: 400px;
    box-sizing: border-box;
    display: block;
    margin-bottom: 30px;
}

.card:hover {
    cursor: pointer;
}
