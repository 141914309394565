.login {
    display: flex;
    flex-direction: column;
}



.login > label > input {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.login > button {
    box-sizing: border-box;
    border-radius: 10px;
    border: none;
    background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
    color: white;
    margin-top: 50px;
    height: 50px;
}

.login > label {
    height: 50px;
    border-radius: 10px;
    margin: 5px;
}

.title {
    align-self: center;
}
