#details-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 25%;
    margin-right: 25%;
}

.head {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.head > h1 {
    margin: 5px;
}

.head-details {
    display: flex;
    flex-direction: row;

}

.head-details > * {
    margin: 5px
}

#spot-image-list {
    display: flex;
    list-style: none;
    padding-left: none;
    width: 100%;

}

#images-container {
    width: 100%;

}

.image {
    border-radius: 10px;
    max-height: 500px;
    width: 100%;
}

.spotInfo {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 10px;
    justify-content: space-between;
}

.moreInfo {
    margin-top: 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    width: 40%
}

.reviews2 {
    display: flex;
}

.reviews2 > * {
    margin: 5px
}

.head-details > button {
    box-sizing: border-box;
    border-radius: 10px;
    border: none;
    background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
    color: white;
}

.spotReviews {
    width: 100%;
}

.add-review {
    box-sizing: border-box;
  border-radius: 10px;
  border: none;
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
  color: white;
}

button:hover {
    cursor: pointer;
}
