#user-name {
    font-weight: bold;
}

#post-date {
    color: gray;
}

.delete-review {
    box-sizing: border-box;
  border-radius: 10px;
  border: none;
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
  color: white;
}
